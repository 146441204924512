import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function LoesungPage() {
  return (
    <Layout className="no-sidebar">
      <SEO title="Lösung" description="Die Karbonisierungs – Technologie ist ein wichtiger Baustein bei der Bewältigung dieser kausalen Herausforderungen." />

      <div className="wrapper">
        <div className="container" id="main">
          <article id="content">
            <header>
              <h2>Lösung</h2>
            </header>

            <p>Die Karbonisierungs – Technologie ist ein wichtiger Baustein bei der Bewältigung dieser kausalen Herausforderungen. Außer einer konsequenten Ressourcenschonung gibt es derzeit kein aussichtsreicheres Lösungskonzept für diesen Themenkomplex.</p>
            <p>Neben Wasser, Boden und Atmosphäre gehört Biomasse zu den wichtigsten globalen CO<sub>2</sub>-Speichern. Bei der konventionellen energetischen Nutzung von Biomasse wird jedoch so viel CO<sub>2</sub> wieder frei, wie zuvor durch die Biomasse gebunden wurde.</p>
            <p>Wird hingegen ausgesuchte Biomasse (Restholz, Silageabfälle, Extraktrückstände, Papierfaserschlamm, Gärreste, Gülle, Klärschlämme etc.) durch Karbonisierung (Verkohlung) veredelt, lassen sich neben der Gewinnung von regenerativer Wärmeenergie wertvolle Produkte wie Pflanzenkohle, Futterkohle, Aktivkohle oder Phosphor als Düngemittel-Substrat erzeugen. Durch diese spezielle stoffliche Umwandlung wird ein Mehrwert gegenüber der herkömmlichen Gewinnung von erneuerbarer Energie aus Biomasse erzeugt.</p>
            <p>Die durch den Prozess gewonnene Kohle ist ein wichtiges Instrument im Kampf gegen den Klimawandel. Ein Kilogramm Karbonisat aus pflanzlichen Reststoffen zum Beispiel bindet 3,6kg CO<sub>2</sub> für mehr als 1000 Jahre. Außerdem ist Pflanzenkohle ein starkes Werkzeug gegen die Desertifikation der Böden. Gewässer und Grundwasser werden aktiv geschützt, wenn nitrathaltige Düngemittel beispielsweise durch Pflanzenkohle oder Kohle aus der Verwertung von kommunalen Klärschlämmen ersetzt werden.</p>
          </article>
        </div>
      </div>
    </Layout>
  )
}
